import { FilterType, InstallationScopes } from 'lib/InstallationScope'

export type JobForInstallation = {
  jobId: string
  environment: string
  created: string
  concluded: string | null
  updatesShapeFile: boolean
  updatesBarcodeFile: boolean
  updatesCrateFile: boolean
  updatesLocalCrateFile: boolean
  status: string
}

export type Installation = {
  commonId?: string
  commonName?: string
  installationReference: InstallationReference
  machineConfig: null | {
    flow: boolean
    crate: boolean
    shouldRunClose: boolean
  }
  history: JobForInstallation[]
}

export type InstallationReference = {
  installationId: string
  serialNumber: string
  chainName?: string
  subChainName?: string
  locationName: string
  country: string
  region?: string
}

type CommonConfigFileStream = {
  id: string
  name: string
}

export type MaintenanceWindow = {
  start: string
  end: string
  tz: string
  weekdays?: number
}

export type ScopeFilter = {
  type: FilterType
  value: string
  commonAssortmentId?: string
  include: boolean
}
export type ScopeFilters = {
  scopeFilters: ScopeFilter[]
}
export type CommonConfig = {
  commonAssortmentId: string
  name: string
  country: string
  maintenanceWindow: MaintenanceWindow
  lockout: boolean
  concurrencyLimit: number | null
  installationScopes: InstallationScopes
  scopeFilters: ScopeFilters
  shapeFileStream: CommonConfigFileStream | null
  barcodeFileStream: CommonConfigFileStream | null
  crateFileStream: CommonConfigFileStream | null
  artikeldatenFileStream: CommonConfigFileStream | null
}

export type FileKeys = {
  latestSourceFile: number
  latestCommonSetId: number
  latestInstallationSetId: number
}

export type MachineAssortmentStatus =
  | 'CREATED'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELLED'
  | 'RECOVERED'
  | 'NOT_COMPATIBLE'

export type AssortmentTypeStatus = {
  created: string | null
  status: MachineAssortmentStatus | null
  jobId: string | null

  fileId: number
  commonModificationSetId: number
  installationModificationSetId: number

  latestAvailable: FileKeys | null
}

export type AssortmentStatus = {
  commonId: string
  shape: AssortmentTypeStatus
  barcode: AssortmentTypeStatus
  crate: AssortmentTypeStatus
  localCrate: AssortmentTypeStatus
  artikeldaten: AssortmentTypeStatus
}

export type InstallationAssortment = {
  installationId: string
  serialNumber: string
  chainName: string | null
  subChainName: string | null
  locationName: string
  country: string
  region: string | null
  assortmentStatus: AssortmentStatus | null
}

export type ScopeConflict = {
  installation: InstallationReference
  conflictingCommonAssortments: { name: string; commonAssortmentId: string }[]
}

export type CommonsAndInstallationsContextData = {
  countries: string[]
  commons: CommonConfig[]
  installations: InstallationAssortment[]
  scopeFilters: ScopeFilters
  commonAssortmentConflicts?: ScopeConflict[]
}

export type CrateFile = {
  crateGroups: CrateGroup[]
}

export type CrateGroup = {
  id: string
  name: string
  accepted: boolean
  inAssortment: boolean
}

export type Modification = {
  referenceObjectId: string
  actionType: string
  actionValue: string
  allowedOverride: boolean
}

export type ShapeFile = {
  bottleGroups: BottleGroup[]
}

export type BottleGroup = {
  groupNumber: string
  superGroupNumber: number
  refundGroup: number
  returnFlag: number
  name: string
}

export type BarcodeAndSonderpfand = {
  barcodeFile: BarcodeFile
  sonderpfandGroups?: SonderpfandGroup[]
}

export type BarcodeFile = {
  barcodes: BarcodeLine[]
}

export type SonderpfandGroup = {
  id: string
  name: string
  barcodes: string[]
}

export type BarcodeLine = {
  code: string
  property?: { key: number; value: number }
  sortCode: number
  flags: number
  comment?: string
}

export type ArtikeldatenFile = {
  content: ArtikeldatenGroup[]
}

export type ArtikeldatenGroup = {
  articleNumber: string,
  articleLongText: string,
  validFrom: string,
  validTo: string
}

export type ValueDiff = {
  newValue: Dictionary
  oldValue: Dictionary
}

export type KeyValue = {
  key: string
  value: string
}

export type Value = {
  value: string
}

export function isKeyValue(value: any): value is KeyValue {
  if ((value as KeyValue).key) {
    return true
  }
  return false
}

export function isValue(value: any): value is Value {
  if ((value as Value).value) {
    return true
  }
  return false
}

export type Dictionary = { [key: string]: number | string | KeyValue | undefined }

export type ListDiff = {
  added: Dictionary[]
  updated: ValueDiff[]
  removed: Dictionary[]
}

export const isListDiff = (diff: Diff | null): diff is ListDiff =>
  (diff as ListDiff) && (diff as ListDiff).added !== undefined

export type Diff = ValueDiff | ListDiff

export type DiffMap = {
  [key: string]: Diff
}

export type StagedFile = {
  storedObjectPath: string
  parsedVersion: string
  latestFileId: number
}

export type ValidationMessage = {
  severity: 'WARNING' | 'ERROR'
  entityId: string | null
  message: string
}

export type FileUploadResult = {
  stagedFile: StagedFile | null
  diff: DiffMap | null
  messages: ValidationMessage[]
}

export type FileSummary = {
  parsedVersion: string
  fileName: string
  created: Date
  createdBy: string
  url: string | null
}

export type CommonReference = {
  commonId: string
  name: string
  installationScope: InstallationScopes
}

export type FileStreamReference = {
  streamId: string
  name: string
  fileType: 'BARCODE' | 'SHAPE' | 'CRATE' | 'ARTIKELDATEN'
  commonAssortmentReferences: Array<CommonReference>
}

export type FileStreamWithHistory = FileStreamReference & {
  current: FileSummary | null
  history: FileSummary[]
}
