import React, { ReactElement, useCallback, useContext, useState } from 'react'
import { Dialog } from './Dialog'
import { FormLayout } from 'styles/FormLayout'
import { ColumnContainer, InnerContainer, Label } from 'styles/PageLayout'
import { MasterdataAssignment } from './masterdata-assignment/MasterdataAssignment'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { ScopeSummary } from './masterdata-assignment/ScopeSummary'
import {
  filterAppliedScopeFilters,
  fromInstallationScope,
  InstallationScopes,
  NodeStatus,
  PathScope,
  Scope,
  ScopeFilterWithStatus,
  StructureNode,
  toDefaultScopeFiltersWithStatus,
  toInstallationScope,
  toScopeFilters,
  toScopeFiltersWithStatus,
} from 'lib/InstallationScope'
import { MaintenanceWindowSelector } from './MaintenanceWindowSelector'
import { CommonsAndInstallationsContextData, MaintenanceWindow, ScopeFilters } from 'domain/DTO'
import styled from 'styled-components'
import { colors } from 'styles'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { getClaimedScopesMap } from '../services/CommonAssortmentService'
import { SRD } from 'srd'

const ErrorMessage = styled.div`
  color: ${colors.RED};
`
const WarningMessage = styled.p`
  color: ${colors.RED};
`
const ErrorList = styled.ul`
  list-style-position: inside;
`
const ErrorListItem = styled.li`
  list-style-type: circle;
`
const ErrorHeading = styled.h3`
  color: ${colors.BLACK};
`

export type FileStreamSummary = {
  id: string
  name: string
  type: 'SHAPE' | 'BARCODE' | 'CRATE' | 'ARTIKELDATEN'
}

export type CommonAssortmentFields = {
  id?: string
  name: string
  lockout: boolean
  country: string
  maintenanceWindow: MaintenanceWindow
  concurrencyLimit: number | undefined | null
  shapeStreamId: string | undefined
  barcodeStreamId: string | undefined
  crateStreamId: string | undefined
  artikeldatenStreamId: string | undefined
  installationScopes: InstallationScopes
  scopeFilters: ScopeFilters
}

type Props = {
  editing?: CommonAssortmentFields
  onComplete: (fields: CommonAssortmentFields) => Promise<void>
  fileStreams: FileStreamSummary[]
  commonsAndInstallations: CommonsAndInstallationsContextData
}

const emptyScopes: Scope[] = []

function getInitialFilters(
  initialFilters: ScopeFilterWithStatus[] | undefined,
  commonsAndInstallations: CommonsAndInstallationsContextData
): ScopeFilterWithStatus[] {
  return toDefaultScopeFiltersWithStatus(commonsAndInstallations.scopeFilters).map((filter) => ({
    ...filter,
    status:
      initialFilters?.find(
        (initialFilter) => initialFilter.type === filter.type && initialFilter.value === filter.value
      )?.status ?? NodeStatus.NONE,
  }))
}

const formatAndSetErrorMessage = (errorMessage: string): ReactElement => {
  if (errorMessage?.includes('Scope conflicts')) {
    let conflicts = errorMessage.split('\n').map((s, index) => index > 0 && <ErrorListItem>{s}</ErrorListItem>)
    return (
      <React.Fragment>
        <ErrorHeading>Error: Scope conflicts</ErrorHeading>
        <ErrorList>{conflicts}</ErrorList>
      </React.Fragment>
    )
  }
  return <div>{errorMessage}</div>
}

export const CommonAssortmentDialog = ({ onComplete, fileStreams, editing, commonsAndInstallations }: Props) => {
  const [showInstallationScopeConfiguration, setShowInstallationScopeConfiguration] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ReactElement | undefined>()

  const [name, setName] = useState(editing?.name ?? '')
  const [modifiedPathScopes, setModifiedPathScopes] = useState<PathScope[]>([])
  const [modifiedScopeFilters, setModifiedScopeFilters] = useState<ScopeFilterWithStatus[]>(
    getInitialFilters(toScopeFiltersWithStatus(editing?.scopeFilters), commonsAndInstallations)
  )
  const [concurrencyLimit, setConcurrencyLimit] = useState<undefined | string>(editing?.concurrencyLimit?.toString())
  const [maintenanceWindow, setMaintenanceWindow] = useState<MaintenanceWindow>(
    editing?.maintenanceWindow ?? {
      start: '01:00',
      end: '04:00',
      tz: 'Europe/Berlin',
    }
  )
  const [lockout, setLockout] = useState(editing?.lockout ?? false)
  const [shapeStreamId, setShapeStreamId] = useState<string | undefined>(editing?.shapeStreamId)
  const [barcodeStreamId, setBarcodeStreamId] = useState<string | undefined>(editing?.barcodeStreamId)
  const [crateStreamId, setCrateStreamId] = useState<string | undefined>(editing?.crateStreamId)
  const [articleDataStreamId, setArticleDataStreamId] = useState<string | undefined>(editing?.artikeldatenStreamId)
  const { selectedCountry } = useContext(SelectedCountryContext)

  const scopes = editing?.installationScopes?.scopes ?? emptyScopes

  const transform = useCallback(
    (original: { data: unknown }) => {
      const structureNodes = original.data as StructureNode[]
      setModifiedPathScopes(fromInstallationScope({ scopes }, structureNodes))
      return {
        structureNodes,
      }
    },
    [scopes],
  )
  const id = editing?.id
  const { data: hierarchyTree } = useRemoteData(
    `${API_HOST}/v1/installation-hierarchy-tree?country=${selectedCountry}${id ? '&commonId=' + id : ''}`,
    transform,
  )
  const operationText = editing ? 'Edit' : 'Create'

  const concurrencyLimitValidationError =
    concurrencyLimit === ''
      ? 'Limit must be defined'
      : concurrencyLimit !== undefined && isNaN(Number(concurrencyLimit))
      ? 'Limit must be a number'
      : undefined

  const maintenanceWindowValidationError =
    maintenanceWindow.weekdays === 0 ? 'At least one day must be enabled for maintenance' : undefined

  return (
    <form
      className="card"
      onSubmit={(e) => {
        e.preventDefault()
        setIsLoading(true)
        onComplete({
          name,
          maintenanceWindow,
          country: selectedCountry,
          concurrencyLimit: concurrencyLimit ? Number(concurrencyLimit) : undefined,
          installationScopes: toInstallationScope(modifiedPathScopes),
          scopeFilters: toScopeFilters(filterAppliedScopeFilters(modifiedScopeFilters)),
          lockout,
          shapeStreamId: shapeStreamId,
          barcodeStreamId: barcodeStreamId,
          crateStreamId: crateStreamId,
          artikeldatenStreamId: articleDataStreamId,
        }).catch((err) => {
          setErrorMessage(formatAndSetErrorMessage(err.toString()))
          setIsLoading(false)
        })
      }}
    >
      <div className="p-md">
        <h3>{`${operationText} Common Assortment`}</h3>
        <InnerContainer>
          <Label>
            <span>Name</span>
            <input
              type="text"
              className="textfield"
              data-testid="input-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Label>
          <Label>
            <input
              type="checkbox"
              className="switch"
              data-testid="check-lockout"
              checked={lockout}
              onChange={() => {
                setLockout(!lockout)
              }}
            />
            <span>Lockout</span>
          </Label>
        </InnerContainer>
        <InnerContainer>Maintenance Window:</InnerContainer>
        <InnerContainer>
          <MaintenanceWindowSelector
            onChange={(value: MaintenanceWindow) => {
              setMaintenanceWindow(value)
            }}
            value={maintenanceWindow}
          />
          <FormLayout.ErrorText>{maintenanceWindowValidationError}</FormLayout.ErrorText>
        </InnerContainer>
        <InnerContainer>
          <Label>
            <div>
              <span>Concurrency Limit? </span>
              <input
                type="checkbox"
                className="checkbox"
                data-testid="input-checkbox-concurrencyLimit"
                checked={concurrencyLimit !== undefined}
                onChange={() => {
                  if (concurrencyLimit === undefined) {
                    setConcurrencyLimit('10')
                  } else {
                    setConcurrencyLimit(undefined)
                  }
                }}
              />
            </div>
            <input
              type="number"
              className="textfield"
              data-testid="input-concurrencyLimit"
              onChange={(e) => setConcurrencyLimit(e.target.value)}
              value={concurrencyLimit ?? ''}
              disabled={concurrencyLimit === undefined}
            />
            <FormLayout.ErrorText>{concurrencyLimitValidationError}</FormLayout.ErrorText>
          </Label>
        </InnerContainer>
        <InnerContainer>
          <Label>
            <span>Shape File Stream</span>
            <select
              className="select"
              data-testid="select-shape"
              onChange={(e) => setShapeStreamId(e.target.value)}
              value={shapeStreamId}
            >
              <option value={undefined} />
              {fileStreams
                .filter(({ type }) => type === 'SHAPE')
                .map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                })}
            </select>
          </Label>
          <Label>
            <span>Barcode File Stream</span>
            <select
              className="select"
              data-testid="select-barcode"
              onChange={(e) => setBarcodeStreamId(e.target.value)}
              value={barcodeStreamId}
            >
              <option value={undefined} />
              {fileStreams
                .filter(({ type }) => type === 'BARCODE')
                .map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                })}
            </select>
          </Label>
          <Label>
            <span>Crate File Stream</span>
            <select
              className="select"
              data-testid="select-crate"
              onChange={(e) => setCrateStreamId(e.target.value)}
              value={crateStreamId}
            >
              <option value={undefined} />
              {fileStreams
                .filter(({ type }) => type === 'CRATE')
                .map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                })}
            </select>
          </Label>
          {selectedCountry === 'DE' && (
            <Label>
              <span>Artikeldaten File Stream</span>
              <select
                className="select"
                data-testid="select-articleData"
                onChange={(e) => setArticleDataStreamId(e.target.value)}
                value={articleDataStreamId}
              >
                <option value={undefined} />
                {fileStreams
                  .filter(({ type }) => type === 'ARTIKELDATEN')
                  .map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    )
                  })}
              </select>
            </Label>
          )}
        </InnerContainer>
        {editing && hasAnyChangedStreams(editing) && (
          <InnerContainer>
            <WarningMessage>
              All stored modifications for items will be removed if the items does not exist in the new streams.
            </WarningMessage>
          </InnerContainer>
        )}
        <ColumnContainer>
          {SRD.match(
            {
              notAsked: () => <div>Empty</div>,
              loading: () => (
                <React.Fragment>
                  <h3>Installation Scope</h3>
                  <div data-testid="loading" className="loadingSpinner"></div>
                </React.Fragment>
              ),
              failure: (err) => <p className="alert alert-danger">{err.body?.title}</p>,
              success: (value) => (
                <React.Fragment>
                  <div>
                    <h3>Installation Scope</h3>
                    <button
                      type="button"
                      className="btn btn-primary-dark py-sm"
                      onClick={() => setShowInstallationScopeConfiguration(true)}
                    >
                      CONFIGURE
                    </button>
                  </div>
                  <ScopeSummary scopes={modifiedPathScopes} data={value.structureNodes} />

                  {showInstallationScopeConfiguration ? (
                    <Dialog
                      onClose={() => setShowInstallationScopeConfiguration(false)}
                      shouldCloseOnOverlayClick={false}
                    >
                      <div className="card" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <MasterdataAssignment
                          hierarchyData={value.structureNodes}
                          claimedScopesMap={getClaimedScopesMap(
                            commonsAndInstallations,
                            value.structureNodes,
                            selectedCountry,
                            modifiedPathScopes
                          )}
                          initialScopes={modifiedPathScopes}
                          initialFilters={modifiedScopeFilters}
                          onComplete={(scope, filters) => {
                            setModifiedPathScopes(scope)
                            setModifiedScopeFilters(filters)
                            setShowInstallationScopeConfiguration(false)
                          }}
                        />
                      </div>
                    </Dialog>
                  ) : null}
                </React.Fragment>
              ),
            },
            hierarchyTree
          )}
        </ColumnContainer>
        <InnerContainer>{errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}</InnerContainer>
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              type="submit"
              className="btn btn-primary-dark py-sm"
              disabled={
                !SRD.isSuccess(hierarchyTree) ||
                (!shapeStreamId && !barcodeStreamId && !articleDataStreamId) ||
                !name ||
                !!maintenanceWindowValidationError ||
                !!concurrencyLimitValidationError ||
                isLoading
              }
            >
              {isLoading && <div className="loadingSpinner" />}
              SUBMIT
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </div>
    </form>
  )

  function hasAnyChangedStreams(editing: CommonAssortmentFields): boolean {
    return (
      editing.barcodeStreamId !== barcodeStreamId ||
      editing.shapeStreamId !== shapeStreamId ||
      editing.crateStreamId !== crateStreamId ||
      editing.artikeldatenStreamId !== articleDataStreamId
    )
  }
}
