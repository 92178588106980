import { CommonConfig, AssortmentStatus, AssortmentTypeStatus } from './DTO'

export type UiStatus =
  | 'UP_TO_DATE'
  | 'NORMAL_PENDING'
  | 'JOB_CREATED'
  | 'JOB_ONGOING'
  | 'WARNING'
  | 'ERROR'
  | 'NOT_COMPATIBLE'
  | 'UNKNOWN'

export type FileStatus = {
  type: string
  uiStatus: UiStatus
  cause: string
}

export function getStatusEmoji(uiStatus: UiStatus) {
  switch (uiStatus) {
    case 'ERROR':
      return '🚨'
    case 'WARNING':
      return '⚠️'
    case 'NORMAL_PENDING':
      return '⚡'
    case 'UP_TO_DATE':
      return '✔️'
    case 'JOB_CREATED':
      return '⏳'
    case 'JOB_ONGOING':
      return '🏗'
    case 'NOT_COMPATIBLE':
      return '🚫'
    case 'UNKNOWN':
      return '💤'
    default:
      return '💤'
  }
}

export function getStatusText(uiStatus: UiStatus) {
  switch (uiStatus) {
    case 'ERROR':
      return 'Failed Update'
    case 'WARNING':
      return 'Cancelled Update'
    case 'NORMAL_PENDING':
      return 'To be updated'
    case 'UP_TO_DATE':
      return 'Up to date'
    case 'JOB_CREATED':
      return 'Job created'
    case 'JOB_ONGOING':
      return 'Job sent to machine'
    case 'NOT_COMPATIBLE':
      return 'No crate unit'
    case 'UNKNOWN':
      return 'Ongoing update > 24h'
    default:
      return 'Unknown status'
  }
}

export const allUiStatuses: UiStatus[] = [
  'ERROR',
  'WARNING',
  'NORMAL_PENDING',
  'UP_TO_DATE',
  'JOB_CREATED',
  'JOB_ONGOING',
  'NOT_COMPATIBLE',
  'UNKNOWN',
]

export function fileTypesForCommon(common: CommonConfig) {
  return [
    common.shapeFileStream ? 'SHAPE' : '',
    common.barcodeFileStream ? 'BARCODE' : '',
    common.crateFileStream ? 'CRATE' : '',
    common.crateFileStream ? 'LCRATE' : '',
    common.artikeldatenFileStream ? 'ARTIKELDATEN' : '',
  ].filter((t) => t !== '')
}

export function getFileStatuses(assortmentStatus: AssortmentStatus, commonFileTypes: string[]): FileStatus[] {
  return [
    getFileStatus(assortmentStatus.shape, 'SHAPE'),
    getFileStatus(assortmentStatus.barcode, 'BARCODE'),
    getFileStatus(assortmentStatus.crate, 'CRATE'),
    getFileStatus(assortmentStatus.localCrate, 'LCRATE'),
    getFileStatus(assortmentStatus.artikeldaten, 'ARTIKELDATEN'),
  ].filter((fs) => commonFileTypes.some((cft) => cft === fs.type))
}

function getFileStatus(latest: AssortmentTypeStatus, type: string): FileStatus {
  if (latest === undefined) {
    return {
      type: '',
      uiStatus: 'ERROR',
      cause: ''
    }
  }
  if (!latest.status) {
    return {
      type,
      uiStatus: 'NORMAL_PENDING',
      cause: 'Never received updates',
    }
  }

  if (latest.status === 'NOT_COMPATIBLE') {
    return {
      type,
      uiStatus: 'NOT_COMPATIBLE',
      cause: 'No crate unit',
    }
  }

  if (latest.status === 'FAILED' || latest.status === 'RECOVERED') {
    return {
      type,
      uiStatus: 'ERROR',
      cause: 'Machine Update Failed',
    }
  }

  if (latest.status === 'CANCELLED') {
    return {
      type,
      uiStatus: 'WARNING',
      cause: 'Machine Update did not finish on time / manually cancelled',
    }
  }

  if (latest.status === 'CREATED' && existsAndIsOlderThan24Hours(latest.created)) {
    return {
      type,
      uiStatus: 'UNKNOWN',
      cause: 'No communication with machine for 24 hours',
    }
  }

  if (latest.status === 'CREATED') {
    return {
      type,
      uiStatus: 'JOB_CREATED',
      cause: 'Part of ongoing update job',
    }
  }

  if (latest.status === 'IN_PROGRESS' && existsAndIsOlderThan24Hours(latest.created)) {
    return {
      type,
      uiStatus: 'UNKNOWN',
      cause: 'No communication with machine for 24 hours',
    }
  }

  if (latest.status === 'IN_PROGRESS') {
    return {
      type,
      uiStatus: 'JOB_ONGOING',
      cause: 'Part of ongoing update job',
    }
  }

  if (latest.fileId !== latest.latestAvailable?.latestSourceFile) {
    return {
      type,
      uiStatus: 'NORMAL_PENDING',
      cause: 'New File Uploaded',
    }
  }

  if (latest.commonModificationSetId !== latest.latestAvailable?.latestCommonSetId) {
    return {
      type,
      uiStatus: 'NORMAL_PENDING',
      cause: 'Modifications to Common Assortment',
    }
  }

  if (latest.installationModificationSetId !== latest.latestAvailable?.latestInstallationSetId) {
    return {
      type,
      uiStatus: 'NORMAL_PENDING',
      cause: 'Modifications to Installation Override',
    }
  }

  return {
    type,
    uiStatus: 'UP_TO_DATE',
    cause: 'Assortment on machine is up to date',
  }
}

const twentyFourHoursInMillis = 24 * 60 * 60 * 1000
function existsAndIsOlderThan24Hours(timestamp: string | null): boolean {
  if (!timestamp) {
    return false
  }
  return new Date().getTime() - new Date(timestamp).getTime() > twentyFourHoursInMillis
}
