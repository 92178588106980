import React, { useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { CommonAssortmentDialog } from 'components/CommonAssortmentDialog'
import { FileStreamOverviewDTO } from '../file-stream-overview/FileStreamOverview'
import { CommonConfig } from 'domain/DTO'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { SRD } from 'srd'
import { CommonsAndInstallationsContext } from '../../domain/CommonsAndInstallationsContext'
import { updateCommonAssortment } from '../../services/CommonAssortmentService'
import { ArrowRightIcon } from '../../icons'
import HomeIcon from '../../icons/HomeIcon'

function LoadingSpinner() {
  return (
    <div className="card">
      <div data-testid="loading">
        <div className="loadingSpinner centerAbsolute" />
      </div>
    </div>
  )
}

export const CommonAssortmentEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  if (!id) {
    throw new Error('Missing parameter "id"')
  }
  const { data: commonRemoteData, refresh: refreshCommon } = useRemoteData<CommonConfig>(`${API_HOST}/v1/common/${id}`)
  const { data: commonsAndInstallationsRemoteData, refresh: refreshCommonsAndInstallations } =
    useContext(CommonsAndInstallationsContext)
  const { data: fileStreamsRemoteData } = useRemoteData<FileStreamOverviewDTO[]>(`${API_HOST}/v1/filestream`)
  const { selectedCountry } = useContext(SelectedCountryContext)

  const combinedRemoteData = SRD.map3(
    (common, commonsAndInstallations, fileStreams) => ({ common, commonsAndInstallations, fileStreams }),
    commonRemoteData,
    commonsAndInstallationsRemoteData,
    fileStreamsRemoteData
  )

  return SRD.match(
    {
      notAsked: () => <LoadingSpinner />,
      loading: () => <LoadingSpinner />,
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load Common Assortment Details
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: ({ common, commonsAndInstallations, fileStreams }) => (
        <div>
          <div className="py-md px-lg flex items-center space-x-md">
            <Link aria-label="Home" to={'/'}>
              <HomeIcon size="1.5rem" />
            </Link>
            <ArrowRightIcon size="1rem" />
            <Link to="/common-assortment">
              <h2>Common</h2>
            </Link>
            <ArrowRightIcon size="1rem" />
            <Link to={'/common-assortment/' + common.commonAssortmentId}>
              <h2>{common.name}</h2>
            </Link>
            <ArrowRightIcon size="1rem" />
            <h2>Edit</h2>
          </div>

          <CommonAssortmentDialog
            fileStreams={fileStreams.map((fs) => ({ name: fs.name, id: fs.streamId, type: fs.fileType }))}
            commonsAndInstallations={commonsAndInstallations}
            editing={{
              id: common.commonAssortmentId,
              name: common.name,
              country: selectedCountry,
              concurrencyLimit: common.concurrencyLimit === null ? undefined : common.concurrencyLimit,
              maintenanceWindow: common.maintenanceWindow,
              lockout: common.lockout,
              installationScopes: common.installationScopes,
              scopeFilters: common.scopeFilters,
              shapeStreamId: common.shapeFileStream ? common.shapeFileStream.id : undefined,
              barcodeStreamId: common.barcodeFileStream ? common.barcodeFileStream.id : undefined,
              crateStreamId: common.crateFileStream ? common.crateFileStream.id : undefined,
              artikeldatenStreamId: common.artikeldatenFileStream ? common.artikeldatenFileStream.id : undefined,
            }}
            onComplete={(fields) => {
              return updateCommonAssortment(id, fields).then(() => {
                refreshCommon()
                refreshCommonsAndInstallations()
                navigate('/common-assortment/' + common.commonAssortmentId)
              })
            }}
          />
        </div>
      ),
    },
    combinedRemoteData
  )
}
