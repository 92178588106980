import { DiffMap, ListDiff } from '../../domain/DTO'

type BarcodeDiff = {
  barcodes: ListDiff
}

type SonderpfandDiff = {
  sonderpfandGroupSections: ListDiff
}

type CrateDiff = {
  crateGroups: ListDiff
}

type ShapeDiff = {
  bottleGroups: ListDiff
}

type ArtikeldatenDiff = {
  content: ListDiff
}

function isBarcodeDiff(diffMap: DiffMap): diffMap is BarcodeDiff {
  if ((diffMap as BarcodeDiff).barcodes) {
    return true
  }
  return false
}

function isShapeDiff(diffMap: DiffMap): diffMap is ShapeDiff {
  if ((diffMap as ShapeDiff).bottleGroups) {
    return true
  }
  return false
}

function isCrateDiff(diffMap: DiffMap): diffMap is CrateDiff {
  if ((diffMap as CrateDiff).crateGroups) {
    return true
  }
  return false
}

export function isSonderpfandDiff(diffMap: DiffMap): diffMap is SonderpfandDiff {
  if ((diffMap as SonderpfandDiff).sonderpfandGroupSections) {
    return true
  }
  return false
}

export function getSonderpfandDiff(diff: DiffMap): ListDiff {
  if (isSonderpfandDiff(diff)) {
    return diff.sonderpfandGroupSections
  }
  return { added: [], updated: [], removed: [] }
}

function isArtikeldatenDiff(diffMap: DiffMap): diffMap is ArtikeldatenDiff {
  return !!(diffMap as ArtikeldatenDiff).content;

}

export function getListDiff(diff: DiffMap): ListDiff[] {
  if (isBarcodeDiff(diff)) {
    return [diff.barcodes, getSonderpfandDiff(diff)]
  } else if (isCrateDiff(diff)) {
    return [diff.crateGroups, getSonderpfandDiff(diff)]
  } else if (isShapeDiff(diff)) {
    return [diff.bottleGroups, getSonderpfandDiff(diff)]
  } else if (isSonderpfandDiff(diff)) {
    return [diff.sonderpfandGroupSections]
  } else if (isArtikeldatenDiff(diff)) {
    return [diff.content]
  }
  return [{ added: [], updated: [], removed: [] }]
}

export function getIdField(diff: DiffMap): string[] {
  if (isBarcodeDiff(diff)) {
    return ['code', 'id']
  } else if (isCrateDiff(diff)) {
    return ['id', 'id']
  } else if (isShapeDiff(diff)) {
    return ['groupNumber', 'id']
  } else if (isSonderpfandDiff(diff)) {
    return ['id']
  } else if (isArtikeldatenDiff(diff)) {
    return ['articleNumber']
  }
  return ['unknown']
}
